import React, { useEffect }  from 'react'
import { useParams } from 'react-router-dom'

export default function Member() {
    let params = useParams();

    useEffect(() => {console.log(params)}, [params])
    return <div>
        member

        <button onClick={() => console.log(params)}>test2</button>
    </div>
}