import React, { useState } from "react";
import "./css/projects.css";
import MyCustomCard from "./modules/altCustomCard";
import { members } from "./data/memberData";

export default function Projects() {
  const [apiUrl] = useState(
    process.env.REACT_APP_API_URL || "https://api.yolked.dev"
  );
  const handleTestClick = () => {
    fetch(`${apiUrl}/api/data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          message: "Hello World!",
        },
      }),
    }).catch((err) => console.error(err));
  };
  return (
    <div style={{ padding: "2%" }}>
      <div className="big-words-project">
        <code>projects</code>
        &nbsp;
        <code>{apiUrl ? apiUrl : "nothing"}</code>
        <button onClick={handleTestClick}>test</button>
      </div>

      <div className="card-container-grid">
        {members.map((name) => (
          <div>
            <MyCustomCard memberName={name} />
          </div>
        ))}
      </div>
    </div>
  );
}
