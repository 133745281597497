import React from 'react'
import logo from '../../random-pic.png'

export default function FrontDisplay() {
    return <div className="main-home-container">
            <div className="picture-section">
                <div className="centering-below">
                    <code style={{fontSize: '100px'}}>We make cool stuff.</code>
                </div>
                <div className="img-container">
                    <img className="elevator-pitch-img" src={logo} alt="logo"></img>
                </div>
            </div>
    </div>
}