export const memberInformation = {
    'angel' : {
        firstName: 'Angel',
        middleName: '',
        lastName: 'Gutierrez',
        schoolInformation: [    
            { 
                title: 'BS',
                school : 'Manhattan College',
                graduationDate : 'Spring 2022',
            },
            {
                title: 'MS',
                school : 'Manhattan College',
                graduationDate : 'Spring 2023',
            }
        ],
        profilePictureRoute: './profile-pictures/angel.jpg'
    },
    'jzheng' : {
        firstName: 'Jason',
        middleName: '',
        lastName: 'Zheng',
        schoolInformation: [    
            { 
                title: 'BS',
                school : 'Manhattan College',
                graduationDate : 'Spring 2024',
            }
        ],
        profilePictureRoute: './profile-pictures/angel.jpg'
    },
    'vcifuentes' : {
        firstName: 'Velbeth',
        middleName: '',
        lastName: 'Cifuentes',
        schoolInformation: [    
            { 
                title: 'BS',
                school : 'Manhattan College',
                graduationDate : 'Spring 2022',
            }
        ],
        profilePictureRoute: './profile-pictures/angel.jpg'
    }
}

export const members = [
    'angel',
    'vcifuentes',
    'jzheng'
]