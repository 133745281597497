import React from 'react'
import '../css/home.css'
import pic from '../profile-pictures/person.jpg'
import otherPic from '../profile-pictures/other_person.jpg'

export default function MeetFounders() {
    return <div className="alt-main-home-container">
        {/* probably like 60 vh, total vh 95 */}
        <div className="founders-section">
            {/* make this a component */}
            <div className="founder-showcase">
                <div className="img-container-founders">
                    <img className='actual-img' src={pic} alt="angel-pic"></img>
                </div>
                <div className="text-with-title">
                    <div className="title-text">
                        Angel Gutierrez
                    </div>
                    <div className="under-title-text">
                        We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.
                    </div>
                </div>
            </div>

            <div className="founder-showcase">
                <div className="text-with-title">
                    <div className="title-text">
                        James Nwaohuocha
                    </div>
                    <div className="under-title-text">
                        We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.We make stuff that solves problems.
                    </div>
                </div>
                <div className="img-container-founders">
                    <img className='actual-img' src={otherPic} alt="james-pic"></img>
                </div>
            </div>
        </div>

    </div>
}