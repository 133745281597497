import React from 'react';
import ReactDOM from 'react-dom';
import Member from './components/singleMember';
import Project from './components/singleProject';
import Projects from './components/allProjects';
import Team from './components/allMembers';
import Home from './components/home';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,  Routes, Route } from 'react-router-dom';
import Navbar from './components/modules/navbar';
import Footer from './components/modules/footer';
import NotFound from './components/notFound';
import './index.css';

ReactDOM.render(
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:projectId" element={<Project />}/>
        
        <Route path="/team" element={<Team />} />
        <Route path="/team/:memberId" element={<Member />} />
        
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
