import React, { useEffect }  from 'react'
import '../css/navbar.css'

export default function Navbar() {
    useEffect(() => { document.title = "EIKO LLC HOME" }, [])
    const changePage = (path) => {window.location.href = `/${path}`; }

    return <div className="navbar-container">
        <div className="actual-navbar">
            <code style={{cursor: "pointer"}} onClick={() => changePage('')}>eiko.team</code>
            <code style={{cursor: "pointer"}} onClick={() => changePage('team')}>team</code>
            <code style={{cursor: "pointer"}} onClick={() => changePage('projects')}>projects</code>
        </div>
    </div>
}