import React from 'react'
import FrontDisplay from './modules/front-display';
import MeetFounders from './modules/meet-founders';

import './css/home.css'

export default function Home() {
    return <div>
        <FrontDisplay />
        <MeetFounders />
    </div>
}